import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
    itemContainer: {
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        marginVertical: 8,
        borderRadius: 8,
        overflow: 'hidden',
        justifyContent: 'space-between',
    },
    headerContainer: {
        width: '100%',
        justifyContent: 'space-between',
        paddingVertical: 2,
        marginBottom: 8,
    },
    titleWithIcon: {
        flexDirection: 'row',
        paddingRight: 8,
        justifyContent: 'space-between',
    },
    subTitle: {
        fontSize: 14,
        fontWeight: '400',
        color: '#475569',
    },
    contentContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: '#9747FF',
        borderRadius: 4,
        padding: 8,
        width: '100%',
    },
    iconStyle: {
        height: 24,
        width: 24,
        overflow: 'hidden',
        resizeMode: 'stretch',
    },
})
