import React, { Component } from 'react'
import { FlatList, ScrollView, Pressable, View, Text } from 'react-native'
import style from './style'
import LinearGradientComponent from '../../LinearGradient'
import { MenuData, SideMenuProps } from './interface'

export interface SideMenuState {
    iconData: boolean
    selectedId: number | string
}

class SideMenu extends Component<SideMenuProps, SideMenuState> {
    constructor(props: any) {
        super(props)
        this.state = {
            iconData: false,
            selectedId: this.props.selectedId || 0,
        }
    }

    handleOnPress = (e, item: MenuData) => {
        if (item?.onPress) {
            item?.onPress(e, item)
        }

        this.setState({
            selectedId: item?.id,
        })
    }

    getSelectedMenu = (menuData = this.props.menuData) => {
        if (!menuData) return

        for (const item of menuData) {
            if (item.id === this.state.selectedId) {
                return item
            }

            if (item.children) {
                const selected = this.getSelectedMenu(item.children)
                if (selected) {
                    return selected
                }
            }
        }
    }

    renderSubMenu = ({ item }: any) => {
        const {
            sideMenuButtonTouchStyle,
            sideMenuButtonStyle,
            sideMenuTouchNameStyle,
            sideMenuNameStyle,
            sideMenuDescriptionTouchStyle,
            sideMenuDescriptionStyle,
        } = this?.props
        const { selectedId } = this.state
        const isSelected = item.id === selectedId

        return (
            <Pressable
                key={item.id}
                style={
                    isSelected
                        ? [
                              style.sideMenuButtonTouchStyle,
                              sideMenuButtonTouchStyle,
                          ]
                        : [style.sideMenuButtonStyle, sideMenuButtonStyle]
                }
                onPress={(e) => this.handleOnPress(e, item)}
            >
                {isSelected ? item?.pressImage || item?.image : item?.image}
                <View style={style.subMenuTextContainer}>
                    <Text
                        style={
                            isSelected
                                ? [
                                      style.sideMenuTouchNameStyle,
                                      sideMenuTouchNameStyle,
                                  ]
                                : [style.sideMenuNameStyle, sideMenuNameStyle]
                        }
                    >
                        {item?.name}
                    </Text>
                    <Text
                        style={
                            isSelected
                                ? [
                                      style.sideMenuDescriptionTouchStyle,
                                      sideMenuDescriptionTouchStyle,
                                  ]
                                : [
                                      style.sideMenuDescriptionStyle,
                                      sideMenuDescriptionStyle,
                                  ]
                        }
                    >
                        {item?.description}
                    </Text>
                </View>
            </Pressable>
        )
    }

    // Finds the top level parent and one level upper parent of the selected menu
    getSelectedTopParent = (menuData = this.props.menuData) => {
        if (!menuData) return []

        for (const menu of menuData) {
            if (menu.id === this.state.selectedId) {
                return [menu]
            }

            if (menu.children) {
                const [topParent, parent] = this.getSelectedTopParent(
                    menu.children,
                )

                // this is the first return
                if (topParent && !parent) {
                    return [menu, menu]
                }

                // this is the consecutive returns, carry the menu(grand parent) to the top
                if (topParent) {
                    return [menu, parent]
                }
            }
        }

        return []
    }

    renderMainMenu = ({ item }: any) => {
        const {
            sideTabButtonTouchTextStyle,
            sideTabButtonTextStyle,
            barGradientColor,
            selectedTabGradientColor,
            selectedId,
        } = this?.props

        const selectedMenu = this.getSelectedMenu()
        const [topParent] = this.getSelectedTopParent()

        const isSelected =
            item.id === selectedMenu?.id || item.id === topParent?.id

        return (
            <LinearGradientComponent
                colors={
                    item.id == selectedId
                        ? selectedTabGradientColor?.length > 0
                            ? selectedTabGradientColor
                            : []
                        : ['transparent', 'transparent']
                }
                style={{ borderRadius: 0 }}
            >
                <View style={style.tabView}>
                    <Pressable
                        style={style.sidebuttoniconNameStyle}
                        onPress={(e) => this.handleOnPress(e, item)}
                    >
                        {isSelected
                            ? item?.pressImage || item?.image
                            : item?.image}
                        <Text
                            style={
                                isSelected
                                    ? [
                                          style.sideTabButtonTouchTextStyle,
                                          sideTabButtonTouchTextStyle,
                                      ]
                                    : [
                                          style.sideTabButtonTextStyle,
                                          sideTabButtonTextStyle,
                                      ]
                            }
                        >
                            {item?.name}
                        </Text>
                    </Pressable>
                    {isSelected && (
                        <>
                            {barGradientColor?.length > 0 && (
                                <LinearGradientComponent
                                    colors={barGradientColor}
                                >
                                    <View style={style.backViewStyle} />
                                </LinearGradientComponent>
                            )}
                        </>
                    )}
                </View>
            </LinearGradientComponent>
        )
    }
    render() {
        const {
            menuData,
            mainMenuVisible,
            mainMenuContainerStyle,
            subMenuContainerStyle,
            subMenuListStyle,
            subMenuTitleStyle,
            bottomContent,
            topContent,
            subMenuBottomContent,
            subMenuTopContent,
        } = this?.props
        const { selectedId, iconData } = this.state

        const selectedMenu = this.getSelectedMenu()
        const hasSubMenu = selectedMenu?.children?.length > 0
        const [_, parent] = this.getSelectedTopParent()

        const shouldRenderSubMenu =
            hasSubMenu || parent || subMenuBottomContent || subMenuTopContent

        return (
            <View style={style.mainView}>
                {mainMenuVisible && (
                    <View
                        style={[
                            style.mainMenuContainer,
                            mainMenuContainerStyle,
                        ]}
                    >
                        {topContent && topContent}
                        <View>
                            <FlatList
                                testID="mainMenuItem"
                                data={menuData}
                                keyExtractor={(item) => `item-${item.id}`}
                                renderItem={(item: any) =>
                                    this?.renderMainMenu(item)
                                }
                            />
                        </View>
                        {bottomContent && bottomContent}
                    </View>
                )}
                {shouldRenderSubMenu && (
                    <View
                        style={[style.subMenuContainer, subMenuContainerStyle]}
                    >
                        <Text
                            style={[
                                style.subMenuTitle,
                                subMenuTitleStyle,
                            ]}
                            testID="subMenuTitle"
                        >
                            {selectedMenu?.children
                                ? selectedMenu.name
                                : parent?.name}
                        </Text>

                        <ScrollView
                            nestedScrollEnabled={true}
                            showsVerticalScrollIndicator={false}
                        >
                            {subMenuTopContent && subMenuTopContent}
                            <FlatList
                                data={
                                    selectedMenu?.children || parent?.children
                                }
                                testID="subMenuItem"
                                keyExtractor={(item) => `item-${item.id}`}
                                renderItem={null}
                                extraData={[iconData, selectedId]}
                                contentContainerStyle={subMenuListStyle}
                                CellRendererComponent={({ item }) => {
                                    return this?.renderSubMenu({ item })
                                }}
                            />
                            {subMenuBottomContent && subMenuBottomContent}
                        </ScrollView>
                    </View>
                )}
            </View>
        )
    }
}

export default SideMenu
