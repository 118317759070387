import React, { FC, ReactElement } from 'react'
import { Pressable, Text, Image, View, StyleSheet } from 'react-native'
import COLORS from '../../../src/utils/colors'
import { CardBaseProps, CardTitleProps } from '../Card/interface'
import Chips, { ChipsProps } from '../Chips'

export interface TaskCardProps extends CardBaseProps, CardTitleProps {
    detail?: React.ReactNode
    images?: (string | React.ReactNode)[]
    statusChipProps?: ChipsProps
    label?: string
    onPressText?: string
}

const TaskCardLoading: React.FC = () => {
    return (
        <View style={styles.taskCardLoadingContainer}>
            <View style={styles.rowSpaceBetween}>
                <View style={styles.taskCardTitleLoading} />

                <View style={styles.taskCardImageLoading} />
            </View>

            <View style={styles.rowSpaceBetween}>
                <View style={styles.taskCardDetailLoading} />

                <View style={styles.taskCardOverdueLoading} />
            </View>
        </View>
    )
}

export const TaskCard: FC<TaskCardProps> = ({
    pressableProps,
    testID,
    containerStyle,
    title,
    detail,
    images,
    statusChipProps,
    loading,
}) => {
    if (loading) {
        return <TaskCardLoading />
    }

    return (
            <Pressable {...pressableProps} style={[styles.taskCardContainer, containerStyle]}  testID={`${testID}-task-card`}>
                <View style={styles.spaceBetween}>
                    <Text style={[styles.taskCardTitleStyle]}>{title}</Text>

                    <View style={[styles.taskCardContentStyle]}>{detail}</View>
                </View>

                <View style={styles.spaceBetween}>
                    <View testID={`${testID}-task-image`}>
                        {images &&
                            images.map((imageOrUrl, index) => {
                                const isUrl = typeof imageOrUrl === 'string'

                                if (isUrl) {
                                    return (
                                        <Image
                                            key={imageOrUrl}
                                            style={[
                                                {
                                                    ...styles.imageStyle,
                                                    right: index * 16,
                                                    zIndex:
                                                        images.length - index,
                                                },
                                            ]}
                                            source={{
                                                uri: imageOrUrl,
                                            }}
                                        />
                                    )
                                } else if (React.isValidElement(imageOrUrl)) {
                                    const node = imageOrUrl as ReactElement

                                    return (
                                        <React.Fragment key={index}>
                                            {React.cloneElement(node, {
                                                style: [
                                                    {
                                                        ...styles.imageStyle,
                                                        right: index * 16,
                                                        zIndex:
                                                            images.length -
                                                            index,
                                                    },
                                                    node.props.style,
                                                ],
                                            })}
                                        </React.Fragment>
                                    )
                                }

                                return null
                            })}
                    </View>

                    {statusChipProps && <Chips {...statusChipProps} />}
                </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    taskCardContainer: {
        backgroundColor: 'white',
        borderRadius: 6,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: 8,
        paddingHorizontal: 20,
        paddingVertical: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskCardTitleStyle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    taskCardContentStyle: {
        paddingTop: 5,
    },
    imageStyle: {
        height: 32,
        width: 32,
        resizeMode: 'cover',
        position: 'absolute',
        borderRadius: 100,
        borderWidth: 1,
        borderColor: '#F0E5FF',
    },
    overdueContainerStyle: {
        marginTop: 14,
        backgroundColor: '#FEF3C7',
        paddingVertical: 2,
        paddingHorizontal: 6,
        borderRadius: 38,
    },
    overdueTextStyle: {
        fontSize: 12,
        color: '#D97706',
        fontWeight: 'bold',
    },
    taskCardLoadingContainer: {
        width: 327,
        height: 104,
        backgroundColor: 'white',
        borderRadius: 6,
        borderLeftWidth: 8,
        paddingHorizontal: 20,
        paddingVertical: 16,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
    },
    taskCardTitleLoading: {
        width: '30%',
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardImageLoading: {
        width: 20,
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardDetailLoading: {
        width: '50%',
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardOverdueLoading: {
        width: '20%',
        height: 20,
        backgroundColor: COLORS.COLOR_WARNING,
        borderRadius: 8,
        marginTop: 10,
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
})
